import * as React from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { navigate } from '../components/wrapper-lang';
import SEO from '../components/seo';
import { Icon } from '../components/icons';
const Container = styled.div `
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 80vh;
`;
const Content = styled.div `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  h1 {
    font-size: 2rem;
    font-weight: bolder;
    margin-bottom: 1rem;
  }
  p {
    font-size: 1rem;
    opacity: 0.8;
  }
`;
const IconWrapper = styled.div `
  position: relative;
  margin-bottom: 2rem;
`;
const Success = ({ data, pageContext }) => {
    const { lang } = pageContext;
    const { success } = data.contentJson;
    React.useEffect(() => {
        setTimeout(() => {
            navigate('/');
        }, 3000);
    }, []);
    return (<Container>
      <SEO {...{
        lang,
    }}/>
      <Content>
        <IconWrapper>
          <Icon iconName="check" size={4}/>
        </IconWrapper>
        <h1>{success.title}</h1>
        <p>{success.subtitle}</p>
      </Content>
    </Container>);
};
export const query = graphql `
  query SuccessPage($lang: String!) {
    contentJson(type: { eq: "form/audition" }, lang: { eq: $lang }) {
      success {
        title
        subtitle
      }
    }
  }
`;
export default Success;

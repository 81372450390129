import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
const SEO = ({ children, description, lang, meta, title }) => {
    const { seo } = useStaticQuery(graphql `
      query SEO {
        seo: contentJson(type: { eq: "seo" }) {
          title
          descKo
          descEn
          descJa
          descZh
          keyword
          image
        }
      }
    `);
    const desc = {
        ko: seo.descKo,
        en: seo.descEn,
        ja: seo.descJa,
        zh: seo.descZh,
    };
    const metaDescription = description || desc[lang];
    return (<Helmet htmlAttributes={{
        lang,
    }} title={title} titleTemplate={`%s | ${seo.title}`} meta={[
        {
            name: 'description',
            content: metaDescription,
        },
        {
            name: 'keywords',
            content: seo.keyword.join(' '),
        },
    ].concat(meta)}>{children}</Helmet>);
};
SEO.defaultProps = {
    lang: 'en',
    meta: [],
    description: '',
};
export default SEO;
